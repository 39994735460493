import { type RegisteredXpos } from 'api/responses/GET_REGISTERED_XPOS';
import { type AppInfo } from 'api/types/AppInfo';
import { type ChapterInfo } from 'api/types/ChapterInfo';
import { type UserInfo } from 'api/types/UserInfo';

export enum keys {
  ACCESSTOKEN = 'tpm_accessToken',
  APP = 'tpm_app',
  APPS = 'tpm_apps',
  CHAPTERS = 'tpm_chapters',
  CHAPTER_CONTENT = 'tpm_chapterContent',
  CHAPTER_INFO = 'tpm_chapterInfo',
  CHAPTER_PROGRESS = 'tpm_chapterProgress',
  ASSETS = 'tpm_assets',
  CONTACT_US = 'tpm_contactUs',
  DARKMODE = 'tpm_darkMode',
  DEBUG = 'tpm_debug',
  HOLIDAY_EFFECTS = 'tpm_holidayEffects',
  LANGUAGE = 'tpm_language',
  PAGES = 'tpm_pages',
  PROGRESS = 'tpm_progress',
  SHOWCASES = 'tpm_showcases',
  TRADE_SHOWS = 'tpm_tradeShows',
  USER = 'tpm_user',
  IMAGE_LIBRARY = 'tpm_imageLibrary',
  DIPLOMA_URL = 'tpm_diplomaUrl',
  COLLECTION = 'tpm_collection',
  REGISTERED_APPS = 'tpm_registeredApps',
  FAVORITES = 'tpm_favorites',
  VIDEO_LIBRARY = 'tpm_videoLibrary',
  WELCOME_VIDEO = 'tpm_welcomeVideo',
  POINTS = 'tpm_points',
  ONE_TIME_CONTENT = 'tpm_oneTimeContent',
  REGISTERED_XPOS = 'tpm_registeredXpos',
}

export type StorageKeys = keyof typeof keys;

export type OneTimeContentKeys = 'welcomeVideo';

// TODO: Add the rest of the keys
export interface StorageValues extends Record<StorageKeys, unknown> {
  LANGUAGE: 'en' | 'nl';
  DARKMODE: boolean;
  HOLIDAY_EFFECTS: boolean;
  ONE_TIME_CONTENT: Record<OneTimeContentKeys, number>;
  CHAPTER_INFO: ChapterInfo[];
  ACCESSTOKEN: {
    token: string;
    expireDate: number;
  };
  USER: UserInfo;
  APPS: AppInfo[];
  DEBUG: unknown;
  REGISTERED_XPOS: RegisteredXpos;
}
