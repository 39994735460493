import * as Sentry from '@sentry/react';

const imagePattern = /\.(jpe?g|png|gif|bmp|tiff|webp|svg)$/i;
export const imgixBaseUrl = 'https://travprocdn.imgix.net/';

const throwSentryError = (url: string) => {
  if (typeof window !== 'undefined') {
    const err = new Error(`Invalid image format: ${url}`);
    console.error(err.message);

    Sentry.captureException(err, {
      tags: {
        image: url,
        url: window.location.href,
      },
    });
  }
};

export const convertImgixUrl = (rawUrl: string, id?: string) => {
  const url = rawUrl.trim();

  if (!imagePattern.test(url)) {
    throwSentryError(url);
  }

  if (id !== undefined) {
    if (url.match(/^https?/)) throwSentryError(url);

    return `${imgixBaseUrl}${id}/${url}`;
  }

  const match = /https?:[/]{2}.*?[/](?<id>[0-9]*)[/](?<name>.*?)$/gi.exec(url)?.groups;

  if (match) {
    return `${imgixBaseUrl}${match.id}/${match.name}`;
  }

  return url;
};
