import { useEffect } from 'react';

import { useParams } from '@tanstack/react-router';

import { setData } from 'api/requests/TRACK_EVENT';

import { CollectionEvent, FavoriteEvent, FileDownloadEvent, RetainEvent, VideoEvent } from './useAnalytics/events';
import { FileShareEvent } from './useAnalytics/events/FileShareEvent';
import { ResourceEvent } from './useAnalytics/events/ResourceEvent';
import { useLocalStorage } from './useHooks/useLocalStorage';

export const useRegisterAnalyticsEvents = () => {
  const [user] = useLocalStorage('USER');
  const { id } = useParams({ strict: false });

  useEffect(() => {
    const callback = (e: Event) => {
      if (id === undefined || user === undefined) {
        return;
      }

      const appId = id;
      const email = user.email;

      if (e instanceof CollectionEvent) {
        void setData({
          appId,
          category: 'collection',
          email,
          title: e.data.title,
          url: e.data.url,
        });
      }

      if (e instanceof FavoriteEvent) {
        void setData({
          appId,
          category: 'favorite',
          email,
          title: e.data.title,
          url: e.data.url,
        });
      }

      if (e instanceof VideoEvent) {
        void setData({
          category: 'videos',
          appId,
          email,
          title: e.data.title,
          url: e.data.url,
        });
      }

      if (e instanceof RetainEvent) {
        void setData({
          appId,
          category: 'retain',
          email,
          chapterId: e.data.chapterId,
        });
      }

      if (e instanceof FileShareEvent) {
        void setData({
          appId,
          category: 'share',
          email,
          name: e.data.name,
          title: e.data.title,
          url: e.data.url,
        });
      }

      if (e instanceof FileDownloadEvent) {
        void setData({
          appId,
          category: 'download',
          email,
          name: e.data.name,
          title: e.data.title,
          url: e.data.url,
        });
      }

      if (e instanceof ResourceEvent) {
        void setData({
          appId,
          category: 'resource',
          email,
          type: e.data.type,
          title: e.data.title,
          url: e.data.url,
        });
      }
    };

    const events: (keyof WindowEventMap)[] = [
      'analytics.collection_click',
      'analytics.favorite',
      'analytics.resource_click',
      'analytics.retain_click',
      'analytics.share',
      'analytics.video_view',
      'analytics.download',
    ];

    events.forEach((e) => {
      window.addEventListener(e, callback);
    });

    return () => {
      events.forEach((e) => {
        window.removeEventListener(e, callback);
      });
    };
  }, [id, user]);
};
