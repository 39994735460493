import { type AppAsset } from 'api/types/AppAsset';

import { type TrackEventParams } from '../types';
import { type EventCategory } from '../types/TrackEventParamsBase';

export const parseResourceTrackingType = (type: AppAsset['type']) => {
  switch (type) {
    case 'weblink':
    case 'iframe':
      return 'web';

    default:
      return type;
  }
};

export interface TrackEventPayload {
  app_id: string;
  email: string;
  action: 'click';
  category: string;
  name: string;
  value: string;
  label: string | null;
}

const fitDataToPayload = <T extends EventCategory>(params: TrackEventParams<T>): TrackEventPayload => {
  const basePayload: Partial<TrackEventPayload> = {
    app_id: params.appId,
    email: params.email,
    action: 'click',
    category: params.category,
    name: undefined,
    value: undefined,
    label: null,
  };

  switch (params['category']) {
    case 'collection':
      basePayload.category = 'collateral';
      basePayload.name = params.url;
      basePayload.value = params.url;
      basePayload.label = params.title;
      break;

    case 'retain':
      basePayload.category = 'retention';
      basePayload.name = 'chapter';
      basePayload.value = params.chapterId;
      break;

    case 'videos':
      basePayload.name = params.category;
      basePayload.value = params.url;
      basePayload.label = params.title;
      break;

    case 'favorite':
      basePayload.name = params.title;
      basePayload.value = params.url;
      basePayload.label = params.title;
      break;

    case 'share':
      basePayload.category = 'shares';
      basePayload.name = params.name;
      basePayload.value = params.url;
      basePayload.label = params.title;
      break;

    case 'download':
      basePayload.category = 'downloads';
      basePayload.name = params.name;
      basePayload.value = params.url;
      basePayload.label = params.title;
      break;

    case 'resource':
      basePayload.category = 'collateral';
      basePayload.name = parseResourceTrackingType(params.type);
      basePayload.value = params.url;
      basePayload.label = params.title;
      break;

    default:
      throw new Error('Unknown category');
  }

  return basePayload as TrackEventPayload;
};

export const createBody = <T extends EventCategory>(params: TrackEventParams<T>) => {
  const data = fitDataToPayload(params);

  const payload = new FormData();

  Object.entries(data).forEach((entry) => {
    payload.append(...(entry as [string, string]));
  });

  return payload;
};
