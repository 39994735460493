import { type AppInfo } from 'api/types/AppInfo';

import { useQuerySourceApps } from './useQuery/useQuerySourceApps';

export const getAppIds = (data: AppInfo[] | undefined) => {
  return (
    data?.map(({ id }) => {
      return id;
    }) || []
  ).sort((a, b) => {
    return a.localeCompare(b);
  });
};

export const useAppIds = () => {
  return getAppIds(useQuerySourceApps()?.data);
};
