import { type AppAsset } from 'api/types/AppAsset';

import { AnalyticsEvent } from './BaseAnalyticsEvent';
import { type AnalyticsEventName } from './types/AnalyticsEventName';

export class ResourceEvent extends AnalyticsEvent<AnalyticsEventName.RESOURCE_CLICK> {
  constructor(public data: { type: AppAsset['type']; url: string; title: string }) {
    super('analytics.resource_click');
  }
}
