import { createBody } from './helpers/createBody';
import { type TrackEventParams } from './types';
import { type EventCategory } from './types/TrackEventParamsBase';

export const setData = async <T extends EventCategory>(params: TrackEventParams<T>) => {
  const response = await fetch('https://crm.travpromobile.com/api/event', {
    method: 'POST',
    body: createBody(params),
  });

  if (response.status === 200) {
    return (await response.json()) as Record<string, unknown>;
  }

  throw new Error('Unknown error occurred while tracking event');
};
