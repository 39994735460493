import { type QueryClient, useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { getData } from 'api/requests/GET_APPS';
import { type AppInfo } from 'api/types/AppInfo';

import { getQueryKey } from 'helpers/getQueryKey';

const queryKey = getQueryKey('APPS');

const queryFn = async () => {
  return getData();
};

export const preloadSourceApps = async (queryClient: QueryClient, id?: string) => {
  const data = await queryClient.fetchQuery({ queryKey, queryFn });

  if (id === undefined) {
    return data.filter((app) => {
      return app.comingSoon === false;
    });
  }

  return data?.filter((app) => {
    return app.id === id;
  });
};

const getSelect = (id?: string) => {
  return (rawData: AppInfo[]) => {
    const data = rawData.filter((app) => {
      return app.comingSoon === false;
    });

    if (id === undefined) {
      return data;
    }

    return data?.filter((app) => {
      return app.id === id;
    });
  };
};

export const useQuerySourceApps = (id?: string, options?: Partial<UseQueryOptions<AppInfo[]>>) => {
  const selectFunc = options?.select || getSelect(id);

  return useQuery({
    enabled: options?.enabled,
    select: selectFunc,
    queryKey,
    queryFn,
  });
};
