import { type CollectionItem } from 'api/types/CollectionItem';

import { AnalyticsEvent } from './BaseAnalyticsEvent';
import { type AnalyticsEventName } from './types/AnalyticsEventName';

export class CollectionEvent extends AnalyticsEvent<AnalyticsEventName.COLLECTION_CLICK> {
  constructor(public data: CollectionItem) {
    super('analytics.collection_click');
  }
}
