import * as Sentry from '@sentry/react';

import { router } from '../router';

const dsn = `https://ff3936f96c96429fb55e1ccc40a59198@o25257.ingest.us.sentry.io/${process.env.SENTRY_PROJECT}`;

Sentry.init({
  dsn,
  maxBreadcrumbs: 50,
  debug: false,
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router), Sentry.replayIntegration()],
  sampleRate: 1,
  tracesSampleRate: 0.2,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
